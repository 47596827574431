import defaultTheme from '@propertypal/shared/src/styles/theme/default';
import { GREY_300, WHITE, WHITE_300, WHITE_200 } from './colors';

const PINK = '#ea4a6d';

const propertyNewsTheme = {
  ...defaultTheme,
  primary: PINK,
  primaryTranslucent: 'rgba(234, 74, 109, 0.1)',
  secondary: PINK,
};

export const propertyNewsNavTheme = {
  ...propertyNewsTheme,
  darkGrey: WHITE,
  textDark: WHITE_300,
  white: GREY_300,
  darkGreyTranslucent: 'rgba(255, 255, 255, 0.9)',
  textLight: WHITE_200,
};

export default propertyNewsTheme;
