import { SearchState, selectSearch } from '@propertypal/shared/src/reducers/search/search.slice';
import PNLogo from '@propertypal/shared/src/resources/logos/property-news-logo.svg?url';
import PPLogo from '@propertypal/shared/src/resources/logos/PropertyPalLogo.svg?url';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import platform from '@propertypal/shared/src/utils/platform';
import { NavLink, STANDARD_LINKS } from '@propertypal/web-app/src/constants/navigation';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  ComponentBox,
  Container,
  LinkBox,
  LinksContainer,
  PPLogoImg,
  PNLogoImg,
  TopLevelLI,
  LinkButton,
  Content,
  ProfilePlaceholder,
} from './Header.style';

const ProfileDropdown = dynamic(() => import('./ProfileDropdown'), { ssr: false });

interface Props {
  cfIpCountry: string | null;
  loggedIn?: boolean;
}

const isActiveSearchSub = (link: NavLink, currentRoute: string, searchState?: SearchState): boolean => {
  // ensure current page is a search page and that link has a category
  if (currentRoute !== '/search' || !link.category || !link.saleType) return false;

  // get search category
  const category = searchState && searchState.resultParams && searchState.resultParams.category;
  const saleType = searchState && searchState.resultParams && searchState.resultParams.saleType;

  if (link.url?.startsWith('/residential-land-for-sale')) {
    return !!(
      category?.includes(link.category) &&
      saleType?.includes(link.saleType) &&
      searchState?.filters.styles.includes('residentialLand')
    );
  }

  if (link.url?.startsWith('/land-for-sale')) {
    return !!(
      category?.includes(link.category) &&
      saleType?.includes(link.saleType) &&
      !searchState?.filters.styles.includes('residentialLand')
    );
  }

  return !!(category?.includes(link.category) && saleType?.includes(link.saleType));
};

const isActiveUrl = (link: NavLink, currentPath: string, currentRoute: string, searchState?: SearchState): boolean => {
  if (link.url === currentPath) {
    return true;
  }

  if (searchState && link.sublinks) {
    return link.sublinks.some((subLink) => {
      return isActiveSearchSub(subLink, currentRoute, searchState);
    });
  }

  return false;
};

const trackLink = (page: string, button: string) => () => {
  trackGaEvent('nav_click', { location: 'Navigation', page, button });
};

const Header: FunctionComponent<Props> = (props) => {
  const router = useRouter();
  const theme = useTheme();
  const containerRef = useRef<HTMLElement | null>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [userMenu, setUserMenu] = useState<boolean>(false);
  const links = STANDARD_LINKS(props.cfIpCountry === 'IE');
  const searchState = useSelector(selectSearch);
  const isHomePage =
    platform.title !== 'PropertyNews' &&
    !!router?.pathname &&
    ['/', '/new-homes/northern-ireland'].includes(router.pathname);

  const togglePanel = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      setUserMenu(false);
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      // close menu on route.
      setActiveIndex(null);
      setUserMenu(false);
    };

    const handleClick = (e: MouseEvent) => {
      // @ts-ignore
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setUserMenu(false);
        setActiveIndex(null);
      }
    };

    document.addEventListener('click', handleClick);
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const generateSubLinks = (topLevelLink: NavLink) => {
    return (
      topLevelLink.sublinks &&
      topLevelLink.sublinks.map((link: NavLink) => {
        const isActive = isActiveSearchSub(link, router.route, searchState);

        return (
          <TopLevelLI key={link.text} role="none" data-sub>
            {!!link.url && (
              <LinkBox
                $active={isActive}
                href={link.url}
                title={link.title}
                role="menuitem"
                data-testid={`${link.testID}${isActive ? 'Active' : 'InActive'}`}
                prefetch={false}
                onClick={trackLink(router.asPath, link.text)}
              >
                {link.text}
              </LinkBox>
            )}
          </TopLevelLI>
        );
      })
    );
  };

  return (
    <Container ref={containerRef} $isHomePage={isHomePage} data-testid="nav-header">
      <Content $isHomePage={isHomePage}>
        <Link aria-label="Home page" href="/" prefetch={false} style={{ display: 'flex' }}>
          {platform.select(
            <PPLogoImg src={PPLogo.src} alt="PropertyPal Logo" />,
            <PNLogoImg src={PNLogo.src} alt="PropertyNews Logo" />,
          )}
        </Link>
        <LinksContainer $isHomePage={isHomePage} role="menubar">
          {links.map((link, index) => {
            const isOpen = activeIndex === index;

            return (
              <TopLevelLI key={link.text} role="none" data-parent>
                {!!link.url && (
                  <LinkBox
                    $active={isActiveUrl(link, router.asPath, router.route, searchState)}
                    href={link.url}
                    prefetch={false}
                    role="menuitem"
                    data-testid={link.testID}
                    onClick={trackLink(router.asPath, link.text)}
                  >
                    {link.text}
                  </LinkBox>
                )}

                {link.sublinks && (
                  <>
                    <LinkButton
                      role="menuitem"
                      $active={isOpen || isActiveUrl(link, router.asPath, router.route, searchState)}
                      onClick={() => togglePanel(index)}
                      data-testid={link.testID}
                    >
                      {link.text}
                    </LinkButton>

                    <ComponentBox
                      data-testid={`${link.testID}-sub-box`}
                      role="menu"
                      style={{ display: isOpen ? 'flex' : 'none' }}
                    >
                      {generateSubLinks(link)}
                    </ComponentBox>
                  </>
                )}
              </TopLevelLI>
            );
          })}
        </LinksContainer>{' '}
        <ProfilePlaceholder>
          <ProfileDropdown
            open={userMenu}
            setOpen={setUserMenu}
            loggedIn={props.loggedIn}
            textColor={theme.white}
            bgColor={theme.textDark}
          />
        </ProfilePlaceholder>
      </Content>
    </Container>
  );
};

export default Header;
